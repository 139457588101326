/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { Box, Container, Checkbox, Flex, Image } from '@chakra-ui/react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/videos.scss';

const Videos = (props) => {
  const { data } = props;
  const [postsList, setPostsList] = useState(data.allWpVideo.edges);
  const [tags, setTags] = useState([]);
  const handleFilters = (event, tag) => {
    if (event.target.checked) {
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
      setTags(tags);
    } else {
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      setTags(tags);
    }

    const filterResult = [];

    data.allWpVideo.edges.filter((node) => {
      if (tags.length === 0) {
        filterResult.push(node);
      } else if (tags.length === 1) {
        const foundPost = node.node.videoCategories.nodes.find(
          (post) => post.slug === tags[0]
        );
        if (foundPost) {
          filterResult.push(node);
        }
      } else {
        const result = [];
        tags.forEach((subtag) => {
          const foundPost = node.node.videoCategories.nodes.find(
            (post) => post.slug === subtag
          );
          if (foundPost) {
            result.push(node);
          }
        });
        const toFindDuplicates = (a) =>
          a.filter((item, index) => a.indexOf(item) !== index);
        const duplicateElements = toFindDuplicates(result);
        if (duplicateElements.length !== 0) {
          duplicateElements.forEach((dup) => {
            if (dup.node.videoCategories.nodes.length >= tags.length) {
              filterResult.push(dup);
            }
          });
        }
      }
    });
    const uniqueResult = [...new Set(filterResult)];
    setPostsList(uniqueResult);
  };
  return (
    <Layout>
      <SEO
        title="Videos About Nutrient Solutions and Crystal Green Fertilizer | Ostara"
        description="Explore videos about nutrient solutions to better understand the advantages of phosphorus fertilizer and how to keep excess nutrients out of waterways."
        keywords="Videos"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main>
        <Box w="100%" p={4} className="videos-body">
          <Container
            className="videos-body--container"
            maxW={1400}
            marginTop={100}
          >
            <h1 className="videos-body--heading">Videos</h1>
            <div className="videos-filter-container">
              <p>
                Stories and data about the real-world applications of our
                Crystal Green Fertilizers and nutrient recovery technology
              </p>

              <div className="videos-filters">
                <h3>Filter by:</h3>
                {data.allWpVideoCategory.edges.map((tag) => (
                  <Checkbox
                    type="checkbox"
                    key={tag.node.slug}
                    className="custom-checkbox"
                    onChange={(event) => handleFilters(event, tag.node.slug)}
                  >
                    <span>✓</span>
                    {tag.node.name}
                  </Checkbox>
                ))}
              </div>
            </div>
          </Container>
          <Container maxW={1400}>
            <Flex flexWrap="wrap">
              {postsList && postsList.length > 0
                ? postsList.map((item) => (
                    <Box key={item.node.id} className="videos--container">
                      <Link to={`/videos/${item.node.slug}`}>
                        <div className="videos--wrapper">
                          <Image
                            className="videos--thumb"
                            placeholder="transparent"
                            src={item.node.featuredImage.node.sourceUrl}
                            alt="Ostara logo"
                          />
                          <Image
                            src="/case-study/case-study-hover.png"
                            alt="Case Study img"
                            className="thumb-overlay"
                          />
                        </div>
                        <h5>{item.node.title}</h5>
                      </Link>
                      <div className="video-tags">
                        {item.node.videoCategories.nodes.map((tag) => (
                          <p key={tag.id}>{tag.name}</p>
                        ))}
                      </div>
                    </Box>
                  ))
                : ''}
            </Flex>
          </Container>
          {/* <CustomButton maxW={230} to="/product" marginTop={10}>
            LOAD MORE
          </CustomButton> */}
        </Box>
      </main>
    </Layout>
  );
};

export default Videos;

export const pageQuery = graphql`
  query {
    allWpVideoCategory {
      edges {
        node {
          name
          slug
        }
      }
    }

    allWpVideo {
      edges {
        node {
          id
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
          slug
          videoCategories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;
